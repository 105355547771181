import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import {searchAdherentForPrecompteAPI} from "./AdherentSlice";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";


export const listDemandeApi = createAsyncThunk(
  "soutient/demande",
  async (data) => {
    const url = `/api/soutienScolaire/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const refuserSoutientAPI = createAsyncThunk(
  "soutient/refuserSoutientAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/soutienScolaire/refusDemande", formData);
    return response.data;
  }
);
export const listTypeFichierSavedSoutienAPI = createAsyncThunk(
  "soutient/listeTypeFichierSaved",
  async (idDemande) => {
    const response = await clientAPI.get("/api/soutienScolaire/listeTypeFichierSaved/" + idDemande);
    return response.data;
  }
);

export const validationSoutientAPI = createAsyncThunk(
  "soutient/validationSoutientAPI",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/soutienScolaire/validation/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listSoutienFilterAPI = createAsyncThunk(
  "soutient/listSoutienFilterAPI",
  async (data) => {
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/soutienScolaire/listFilter",data);
    return response.data;
  }
);

export const getFileSoutienByDemandeAndType = createAsyncThunk(
  "soutient/getFileByDemandeAndType",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/soutienScolaire/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data, data.libelle + ".jpeg");
        else fileDownload(res.data, data.libelle + ".pdf");
      });
  }
);

export const getFileSoutienByDemandeAndEnfant = createAsyncThunk(
  "soutient/getFileSoutienByDemandeAndEnfant",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/soutienScolaire/getFileByDemandeAndEnfant/${data.idDemande}/${data.idEnfant}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data,  ".jpeg");
        else fileDownload(res.data,   "Attestation_Scolaire.pdf");
      });
  }
);


const SoutienScolaire = createSlice({
  name: "demande",
  initialState: {
    listDemande: [],
    listFichierSaved: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listDemandeApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
      })
      .addCase(listSoutienFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
        console.log('le nbr est ::::: ',state.listDemande )
      })
      .addCase(listTypeFichierSavedSoutienAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listFichierSaved = action.payload;
      })

  },
});


export const {} = SoutienScolaire.actions;

export default SoutienScolaire.reducer;
